<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Panel header="Gerenciamento de Registros">
                <Toolbar class="p-mb-3">
                    <template v-slot:left>
                        <b>SELECIONE:</b>
                        <Dropdown v-model="selectedEdital" class="p-ml-2 p-mr-2" :options="editais" :filter="true"
                            optionLabel="nedital" placeholder="Escolha o Edital"
                            @change="findRegistroByEditalId(selectedEdital.id)">
                        </Dropdown>
                        <Button label="Novo" icon="pi pi-plus" class="p-button-success" @click="showCreateEdital()"
                            :disabled="!selectedEdital.id" />
                    </template>
                </Toolbar>
                <DataTable ref="dt" :value="registros" class="p-datatable-sm" v-model:selection="selectedRegistro"
                    dataKey="id" :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]" :filters="filters"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} usuários">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5>{{ selectedEdital.nedital }}</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Pesquisar..." />
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        <div class="p-text-center">Nenhum resultado encontrado...</div>
                    </template>
                    <Column header="Data" field="publicacao" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">Data:</span>
                            {{ $DateTime.formatDate(slotProps.data.publicacao) }}
                        </template>
                    </Column>
                    <Column header="Descrição" field="descricao" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">Descrição:</span>
                            {{ slotProps.data.descricao }}
                        </template>
                    </Column>
                    <Column header="Anexo">
                        <template #body="slotProps">
                            <Button label="ANEXO" class="p-button-raised p-button-secondary" icon="fa-solid fa-folder"
                                @click="showAnexo(slotProps.data)" />
                        </template>
                    </Column>
                    <Column header="Ações">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2"
                                @click="showUpdateEdital(slotProps.data)" v-tooltip.top="'CLIQUE PARA EDITAR'" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                @click="showDeleteEdital(slotProps.data)" v-tooltip.top="'CLIQUE PARA EXCLUIR'" />
                        </template>
                    </Column>
                </DataTable>
            </Panel>
        </div>
    </div>

    <!--MODAL CADASTRAR-->
    <Dialog v-model:visible="registroDialog" :header="titleDialog" :style="{ width: '500px' }" :modal="true"
        @hide="hideDialogs()">
        <form @submit.prevent="createOrUpdateEdital(v$.registro.$invalid)">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 ">
                    <label for="data">Data da Publicação</label>
                    <InputText 
                        id="data" 
                        type="date"
                        v-model="v$.registro.publicacao.$model" 
                        placeholder="Digite o N° do Edital."
                        :class="{ 'p-invalid': submitted && v$.registro.publicacao.$invalid }"
                        autofocus 
                    />
                    <small class="p-invalid" v-if="submitted && v$.registro.publicacao.$invalid">
                        Data da Publicação é obrigatório.
                    </small>
                </div>
                <div class="p-field p-col-12">
                    <label for="desc">Descrição</label>
                    <Textarea  
                        id="desc" 
                        v-model="v$.registro.descricao.$model" 
                        placeholder="Digite a descrição."
                        rows="5" cols="30"
                        :class="{ 
                                'p-invalid': submitted && 
                                v$.registro.descricao.$invalid }" />
                    <small class="p-invalid" v-if="submitted && v$.registro.descricao.$invalid">
                        Descrição é obrigatório.
                    </small>
                </div>
            </div>
            <Button label="Salvar" class="p-button-raised p-mr-2" icon="pi pi-check" type="submit" />
            <Button label="Cancelar" icon="pi pi-times" class="p-button-raised p-button-text" @click="hideDialogs()" />
        </form>
    </Dialog>
    <!--FIM MODAL CADASTRAR-->

    <!--MODAL DELETAR-->
    <Dialog v-model:visible="deleteRegistroDialog" :header="titleDialog" :style="{ width: '450px' }" :modal="true"
        @hide="hideDialogs()">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="registro">Tem certeza de que deseja excluir o registro
                <b>{{ registro.descricao }}?</b>
            </span>
            
        </div>
        <hr>
        <div>
            <span>
                <b>Obs: </b>Se tiverem anexos atrelados a este registro ele não poderá ser excluído.
            </span>
        </div>
        <template #footer>
            <Button label="Sim" icon="pi pi-check" class="p-button-raised" @click="deleteEdital()" />
            <Button label="Não" icon="pi pi-times" class="p-button-raised p-button-text" @click="hideDialogs()" />
        </template>
    </Dialog>
    <!--FIM MODAL DELETAR-->

    <!-- MODAL DE ANEXOS -->
    <Dialog v-model:visible="dialogAnexo" :header="titleDialog" :style="{ width: '650px' }" :modal="true">
        <div class="p-d-flex p-ai-center p-jc-center p-mb-3">
            <InputText id="uploadAnexo" type="file" accept="application/pdf,.xls,.xlsx,csv,.doc,.docx"
                @change="uploaderAnexo" />
        </div>
        <ProgressBar v-if="progressbar" mode="indeterminate" style="height: 0.5em" />
        <div class="p-d-flex p-ai-center p-jc-center p-mb-3">
            <small>Em caso de algum <b>impedimento com tamanho do arquivo</b> utilize
                alguma das ferramentas para compressão. Clique nos links :
                <a href="https://www.ilovepdf.com/pt/comprimir_pdf" target="_blank" rel="noopener noreferrer">ILove PDF</a>
                /
                <a href="https://pdfcompressor.com/pt/" target="_blank" rel="noopener noreferrer">PDF Compressor</a>

                /
                <a href="https://smallpdf.com/pt/comprimir-pdf" target="_blank" rel="noopener noreferrer">Small PDF</a>.
            </small>
            <small> </small>
        </div>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <DataTable :value="anexos" :paginator="true" :rows="5"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} anexos."
                    responsiveLayout="scroll">
                    <template #empty>
                        <div class="p-text-center">Nenhum anexo encontrado...</div>
                    </template>
                    <Column field="id" header="#"></Column>
                    <Column field="nome" header="Nome"></Column>
                    <Column field="identificador" header="Identificador"></Column>
                    <Column field="url" header="Anexo">
                        <template #body="slotProps">
                            <Button label="DOWNLOAD" class="p-button-raised p-button-success" icon="pi pi-download"
                                v-tooltip.top="'CLIQUE PARA BAIXAR/VISUALIZAR'" @click="openAnexo(slotProps.data.url)" />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-secondar"
                                v-tooltip.top="'CLIQUE PARA EXCLUIR'" :disabled="disabledButton"
                                @click="deleteAnexo(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <template #footer> </template>
    </Dialog>
    <!-- FIM MODAL DE ANEXOS -->
</template>
  
<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import { FilterMatchMode } from "primevue/api";

import EditalService from "@/service/Edital/EditalService";
import RegistroChamamentoService from "@/service/RegistroChamamento/RegistroChamamentoService";
import AnexoChamamentoService from "@/service/AnexoChamamento/AnexoChamamentoService";


export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            editais: null,
            selectedEdital: {
                id: null
            },
            anexos: null,
            anexo: {
                id: null,
                url: null,
                nome: null,
                identificador: null,
                dt_atualizacao: null,
                registro: {
                id: null,
                },
            },
            submitted: false,
            selectedAnexo: null,
            selectedRegistro: null,
            registros: null,
            registro: {
                id: null,
                publicacao: null,
                descricao: null,
                edital: {
                    id: null,
                    municipio: null,
                    visibilidade: true,
                    status: true,
                    nhospital: null,
                    npae: null,
                    nedital: null
                }
            },
            registroDialog: false,
            deleteRegistroDialog: false,
            dialogAnexo: false,
            filters: {},
            progressbar: false,
            disabledButton: false,
            disabledButtonHabilitarAndDesabilitar: false
        };
    },
    editalService: null,
    registroChamamentoService: null,
    anexoChamamentoService: null,
    validations() {
        return {
            registro: {
                publicacao: {
                    required,
                },
                descricao: {
                    required,
                },
            },
        };
    },
    created() {
        this.editalService = new EditalService();
        this.registroChamamentoService = new RegistroChamamentoService();
        this.anexoChamamentoService = new AnexoChamamentoService();
        this.storageRef = this.$firebaseApp.storage();
        this.initFilters();
    },
    mounted() {
        this.findAllEditais();
    },
    methods: {
        showCreateEdital() {
            this.titleDialog = "Novo  Registro";
            this.registroDialog = true;
        },
        showUpdateEdital(registro) {
            this.registro = registro;
            this.registro.publicacao =  this.$DateTime.formatDateDB(this.registro.publicacao);
            this.titleDialog = 'Atualização de Registro';
            this.registroDialog = true;
        },
        showDeleteEdital(registro) {
            this.registro = registro;
            this.titleDialog = "Exclusão de Registro";
            this.deleteRegistroDialog = true;
        },
        showAnexo(registro) {
            this.registro = registro;
            this.titleDialog = "Lista de Anexos"
            this.findAnexoByRegistroId(registro.id);
            this.dialogAnexo = true;
        },
        openAnexo(anexo) {
            window.open(anexo, "blank");
        },
        hideDialogs() {
            this.registro = {
                id: null,
                publicacao: null,
                descricao: null,
                edital: {
                    id: null,
                    municipio: null,
                    visibilidade: true,
                    status: true,
                    nhospital: null,
                    npae: null,
                    nedital: null
                }
            };
            this.submitted = false;
            this.registroDialog = false;
            this.deleteRegistroDialog = false;
        },
        findAllEditais() {
            this.editalService
                .findAll()
                .then((data) => {
                    this.editais = data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        findRegistroByEditalId(id) {
            this.registroChamamentoService
                .findRegistroChamamentoById(id)
                .then((data) => {
                    this.registros = data;
                    //console.log(this.selectedEdital);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    }
                });
        },
        createOrUpdateEdital(isFormValid) {
            this.submitted = true;
            if (isFormValid) {
                return;
            }
            if (this.registro.id === null) {
                this.registro.edital = this.selectedEdital;
                this.registroChamamentoService
                    .create(this.registro)
                    .then((data) => {
                        this.$msgSuccess(data);
                        this.findRegistroByEditalId(this.selectedEdital.id);
                        this.hideDialogs();
                    }).catch((error) => {
                        this.$msgErro(error);
                    });
            } else {
                this.registroChamamentoService
                    .update(this.registro.id, this.registro)
                    .then((data) => {
                        if (data.status === 200) {
                            this.$msgSuccess(data);
                            this.findRegistroByEditalId(this.selectedEdital.id);
                            this.hideDialogs();
                        }
                    }).catch((error) => {
                        this.$msgErro(error);
                    });
            }
        },
        deleteEdital() {
            this.registroChamamentoService
                .delete(this.registro.id)
                .then((data) => {
                    if (data.status === 204) {
                        this.$msgSuccess(data);
                        this.findRegistroByEditalId(this.selectedEdital.id);
                        this.hideDialogs();
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$msgWarn(null);
                    } else {
                        this.$msgErro(error);
                    }
                });
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        uploaderAnexo(event) {
            this.progressbar = true;
            this.disabledButton = true;
            var arquivo = event.target.files[0];
            
            if (arquivo.size > 6000000) {
                alert("Não foi possivel realizar esta ação! Arquivo acima de 6MB.");
                this.progressbar = false;
                this.disabledButton = false;
                return;
            }
            //gerando nome do arquivo de forma aleatoria
            let identificador = Math.floor(Date.now() * Math.random()).toString(18);
            this.storageRef
                .ref()
                .child(`CHAMAMENTO_PUBLICO/EDITAL_${this.selectedEdital.id}/` + identificador)
                .put(arquivo)
                .then((snapshot) => {
                this.$toast.add({
                    severity: "success",
                    summary: "Alerta!",
                    detail: "Upload realizado com sucesso.",
                    life: 3000,
                });
           
                this.getDownloadURL(
                    snapshot,
                    this.registro.id,
                    identificador,
                    arquivo.name
                );
                });
        },
        getDownloadURL(snapshot, idRegistro, descricao, nome) {
            snapshot.ref.getDownloadURL().then((downloadURL) => {
                this.anexo = {
                    id: null,
                    url: downloadURL,
                    nome: nome,
                    identificador: descricao,
                    dt_cadastro: null,
                    dt_atualizacao: null,
                    registro: {
                        id: idRegistro,
                    },
                };
                this.saveAnexo(this.anexo);
            });
        },
        findAnexoByRegistroId(idRegistro) {
            this.anexoChamamentoService
                .findByRegistroId(idRegistro)
                .then((data) => {
                this.anexos = data;
                })
                .catch((error) => {
                if (error.response.status === 401) {
                    this.$msgWarn(null);
                }
            });
        },
        saveAnexo(param) {
            this.anexoChamamentoService
            .create(param)
                .then((data) => {
                if (data.status === 201) {
                    this.progressbar = false;
                    this.disabledButton = false;
                    this.findAnexoByRegistroId(this.registro.id);
                    this.$toast.add({
                        severity: "success",
                        summary: "Alerta!",
                        detail: "Registro salvo com sucesso.",
                        life: 3000,
                    });
                    document.getElementById("uploadAnexo").value = "";
                }
                })
                .catch((error) => {
                if (error.response.status === 401) {
                    this.$msgWarn(null);
                }
            });
        },
        deleteAnexo(anexo) {
            this.progressbar = true;
            this.disabledButton = true;
            this.storageRef
                .ref()
                .child(`CHAMAMENTO_PUBLICO/EDITAL_${this.selectedEdital.id}/` + anexo.identificador)
                .delete()
                .then(() => {
                    this.deleteAnexoById(anexo.id);
                })
                .catch((error) => {
                    console.error("Error removing document: ", error);
                });
        },
        deleteAnexoById(id) {
            this.anexoChamamentoService
                .delete(id)
                .then((data) => {
                    if (data.status === 204) {
                        this.disabledButton = false;
                        this.progressbar = false;
                        this.findAnexoByRegistroId(this.registro.id);
                        this.$toast.add({
                            severity: "success",
                            summary: "Alerta!",
                            detail: "Registro excluido com sucesso.",
                            life: 3000,
                        });
                    }
                })
                .catch((error) => {
                if (error.response.status === 401) {
                    this.$msgWarn(null);
                }
            });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-invalid {
    color: red;
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.visibilidade-true {
        background: #c8e6c9;
        color: #256029;
    }

    &.visibilidade-false {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-true {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-false {
        background: #ffcdd2;
        color: #c63737;
    }
}

.p-datatable-sm .p-datatable-tbody>tr>td .p-column-title {
    display: none !important;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-sm {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr>td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: 0.4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -0.4em 1em -0.4em -0.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                    text-align: right;
                }

                .p-rating {
                    display: inline-block;
                }
            }
        }
    }
}
</style>
  